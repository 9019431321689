import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@tkww/the-bash-frontend';
import src from 'public/images-next/square_feet.svg';

const SquareFeet = (props) => <Icon {...props} src={src} />;

SquareFeet.propTypes = {
  alt: PropTypes.string,
};

SquareFeet.defaultProps = {
  alt: 'Square footage',
};

export default SquareFeet;
