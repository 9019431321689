import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tkww/the-bash-frontend';

import { StarRating } from 'components/VendorRating';

export const VerifiedReviews = ({ numberOfReviews, rating, showShortReviewsCopyOnDesktop, sx }) => (
  <Box display="flex" alignItems="center" sx={sx}>
    <StarRating stars={rating} />
    <Typography variant="small" data-testid="reviews">
      <Box
        component="span"
        sx={(theme) => ({
          marginRight: 0.5,
          [theme.breakpoints.up('md')]: {
            fontWeight: showShortReviewsCopyOnDesktop ? 'none' : 'bold',
            '&::before': {
              content: showShortReviewsCopyOnDesktop ? '"("' : '""',
            },
            '&::after': {
              content: showShortReviewsCopyOnDesktop ? '")"' : '""',
            },
          },
        })}
      >
        {numberOfReviews}
      </Box>
      <Box
        component="span"
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: showShortReviewsCopyOnDesktop ? 'none' : 'initial',
          },
        })}
      >
        {numberOfReviews > 1 ? 'reviews' : 'review'}
      </Box>
    </Typography>
  </Box>
);

VerifiedReviews.propTypes = {
  numberOfReviews: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  showShortReviewsCopy: PropTypes.bool,
};

VerifiedReviews.defaultProps = {
  showShortReviewsCopy: false,
};

export default VerifiedReviews;
