import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import Star, { types } from './Star';
import sxPropType from 'propTypes/sx';

const getStar = (key, type) => <Star key={key} type={type} />;

export const StarRating = (props) => {
  const { stars, sx } = props;

  return (
    <Box display="flex" mr="4px" sx={sx}>
      {Array.from({ length: Math.floor(stars) }, (v, i) => getStar(i, types.full))}
      {stars % 1 === 0.5 && getStar(0.5, types.halfFull)}
      {Array.from({ length: 5 - Math.round(stars) }, (v, i) => getStar(i, types.empty))}
    </Box>
  );
};

StarRating.propTypes = {
  stars: PropTypes.oneOf([0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]),
  sx: sxPropType,
};

StarRating.defaultProps = {
  stars: 0,
  sx: {},
};

export default StarRating;
