import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@tkww/the-bash-frontend';
import src from 'public/images-next/guests_alt.svg';

const GuestsIcon = (props) => <Icon {...props} src={src} />;

GuestsIcon.propTypes = {
  alt: PropTypes.string,
};

GuestsIcon.defaultProps = {
  alt: 'Max capacity',
};

export default GuestsIcon;
