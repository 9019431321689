import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@tkww/the-bash-frontend';
import src from 'public/images-next/clock.svg';

const ClockIcon = (props) => <Icon {...props} src={src} />;

ClockIcon.propTypes = {
  alt: PropTypes.string,
};

ClockIcon.defaultProps = {
  alt: 'Member Since',
};

export default ClockIcon;
