import React from 'react';
import PropTypes from 'prop-types';
import { getIconStateStyles } from '@tkww/the-bash-frontend';

import startFullIcon from 'public/images-next/rating/star/full.svg';
import startHalfFullIcon from 'public/images-next/rating/star/half-full.svg';
import startEmptyIcon from 'public/images-next/rating/star/empty.svg';
import { Box } from '@mui/material';

export const types = Object.freeze({
  full: 'starFull',
  halfFull: 'starHalfFull',
  empty: 'starEmpty',
});

export const Star = ({ type }) => {
  return (
    <Box
      display="flex"
      mr="2px"
      sx={{
        // WARNING: Do not use dynamic props for these classes or svg data will be duplicated
        // for each instance of this component
        '& .starFull': {
          ...getIconStateStyles(startFullIcon, null, null, false),
        },
        '& .starHalfFull': {
          ...getIconStateStyles(startHalfFullIcon, null, null, false),
        },
        '& .starEmpty': {
          ...getIconStateStyles(startEmptyIcon, null, null, false),
        },
      }}
    >
      <Box component="span" sx={{ minWidth: 18, minHeight: 18, height: 18 }} className={type} />
    </Box>
  );
};

Star.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
};

Star.defaultProps = {
  type: types.full,
};

export default Star;
