import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { getIconStateStyles } from '@tkww/the-bash-frontend';

import guestAltIcon from 'public/images-next/guests_alt.svg';

const GuestsAlt = ({ className, ...props }) => (
  <Box
    component="span"
    sx={{
      display: 'inline-block',
      minWidth: 26,
      minHeight: 23,
      ...getIconStateStyles(guestAltIcon, null, null, false),
    }}
    className={className}
    {...props}
  />
);

GuestsAlt.propTypes = {
  className: PropTypes.string,
};

GuestsAlt.defaultProps = {
  className: '',
};

export default GuestsAlt;
