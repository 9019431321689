import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { getIconStateStyles } from '@tkww/the-bash-frontend';

import dollarCircleIcon from 'public/images-next/dollar_circle.svg';

const DollarCircle = ({ className, ...props }) => (
  <Box
    component="span"
    sx={{
      display: 'inline-block',
      minWidth: 24,
      minHeight: 24,
      ...getIconStateStyles(dollarCircleIcon, null, null, false),
    }}
    {...props}
    className={className}
  />
);

DollarCircle.propTypes = {
  className: PropTypes.string,
};

DollarCircle.defaultProps = {
  className: '',
};

export default DollarCircle;
