import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@tkww/the-bash-frontend';
import src from 'public/images-next/trophy.svg';

const TrophyIcon = (props) => <Icon {...props} src={src} />;

TrophyIcon.propTypes = {
  alt: PropTypes.string,
};

TrophyIcon.defaultProps = {
  alt: 'Awards &amp; Badges',
};

export default TrophyIcon;
