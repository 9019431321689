export const VENUE_CATEGORY_ID = 2803;
export const CHECK_AVAILABILITY_TEXT = 'Check Availability';

export const MARKET_TILE_IMAGES = {
  'austin-tx': 'https://media-api.xogrp.com/images/df7a90ad-07e4-4287-889b-285712648885',
  'charlotte-nc': 'https://media-api.xogrp.com/images/a8bae15f-44f8-4caa-ba12-4926489dce16',
  'chicago-il': 'https://media-api.xogrp.com/images/f6fc1f1e-da4c-4420-9db1-7769242a0ccc',
  'denver-co': 'https://media-api.xogrp.com/images/ce41a6b3-106e-4d36-954d-33ceffa2fe66',
  'houston-tx': 'https://media-api.xogrp.com/images/87f4dfd5-8517-4257-abf1-875e224b2a1d',
  'los-angeles-ca': 'https://media-api.xogrp.com/images/33e35cb4-3ea9-4360-aeb8-c67a777ad8ad',
  'miami-fl': 'https://media-api.xogrp.com/images/69397423-6a79-487b-ba4e-c9fde8f0f219',
  'new-york-city-ny': 'https://media-api.xogrp.com/images/1a64868d-1794-46db-b991-ff2288120ab5',
  'philadelphia-pa': 'https://media-api.xogrp.com/images/a4be3810-c1c9-44e8-bf56-b97a1b360552',
  'phoenix-az': 'https://media-api.xogrp.com/images/97949184-a005-401f-8484-a8df05890269',
  'san-francisco-ca': 'https://media-api.xogrp.com/images/cbd71697-8c72-4c3c-a082-7441a989f649',
  'seattle-wa': 'https://media-api.xogrp.com/images/cd996e39-f4f8-47ae-b24b-2a8c78584a0b',
  'washington-dc': 'https://media-api.xogrp.com/images/665c96b8-acb4-45c4-8b6e-1c9823aa931b',
};
