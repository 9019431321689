import React from 'react';
import { Box } from '@mui/material';

import playIconIdle from 'public/images-next/play_icon.svg';
import playIconPress from 'public/images-next/play_icon-hover.svg';

export const PlayButton = () => (
  <Box
    sx={{
      height: 61,
      width: 61,
      background: `url(${playIconIdle})`,
      '&:hover,&:focus,&:active': {
        background: `url(${playIconPress})`,
      },
    }}
  />
);

export default PlayButton;
