import { VENUE_CATEGORY_ID } from 'constants/Venues';

export const slugFromUrl = url => url.match(/\/profiles\/(.*)/)[1];

export const getCapacityText = (min, max) => {
  if (min && max) return `${min} to ${max}`;
  if (!min && max) return `up to ${max}`;

  return 'Not Available';
};

export const isServiceTypeVenue = serviceType => {
  if (!serviceType) {
    throw new Error('isServiceTypeVenue requires serviceType');
  }

  return serviceType.id === VENUE_CATEGORY_ID || serviceType.searchCategoryId === VENUE_CATEGORY_ID;
};

export default {
  slugFromUrl,
  getCapacityText,
  isServiceTypeVenue,
};
