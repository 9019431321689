import React from 'react';
import PropTypes from 'prop-types';

import { VerifiedBookings, VerifiedReviews } from 'components/VendorRating';
import { Box } from '@mui/material';
import getSxAttributes from 'helpers/getSxAttributes';

export const VendorRating = ({
  numberOfBookings,
  numberOfReviews,
  rating,
  stackOnMobile,
  showBookings,
  showShortReviewsCopyOnDesktop,
  sx,
  reviewsSx,
  bookingsSx,
}) => {
  const shouldShowStarRating = !!rating && rating > 0;
  const shouldShowBookings = showBookings && !!numberOfBookings && numberOfBookings > 0;

  if (!(shouldShowStarRating || shouldShowBookings)) return null;
  let conditionalRootMobileStyles = {};
  let conditionalVerifiedReviewsMobileStyles = {};
  let conditionalVerifiedBookingsMobileStyles = {};

  if (stackOnMobile) {
    conditionalRootMobileStyles = {
      flexWrap: 'nowrap',
      flexDirection: 'column',
    };
    conditionalVerifiedReviewsMobileStyles = {
      marginBottom: '16px',
    };
    conditionalVerifiedBookingsMobileStyles = {
      marginBottom: '12px',
    };
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        marginBottom: 0.5,
        ...conditionalRootMobileStyles,
        [theme.breakpoints.up('md')]: {
          alignItems: 'center',
          flexWrap: 'wrap',
          flexDirection: 'row',
        },
        ...getSxAttributes(theme, sx),
      })}
      data-testid="ratings"
    >
      {shouldShowStarRating && (
        <VerifiedReviews
          rating={rating}
          numberOfReviews={numberOfReviews}
          showShortReviewsCopyOnDesktop={showShortReviewsCopyOnDesktop}
          sx={(theme) => ({
            ...conditionalVerifiedReviewsMobileStyles,
            [theme.breakpoints.up('md')]: {
              margin: '4px 12px 4px 0',
            },
            ...getSxAttributes(theme, reviewsSx),
          })}
        />
      )}
      {shouldShowBookings && (
        <VerifiedBookings
          numberOfBookings={numberOfBookings}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              ...conditionalVerifiedBookingsMobileStyles,
            },
            ...getSxAttributes(theme, bookingsSx),
          })}
        />
      )}
    </Box>
  );
};

VendorRating.propTypes = {
  lazyLoadStarRating: PropTypes.bool,
  numberOfBookings: PropTypes.number,
  numberOfReviews: PropTypes.number,
  rating: PropTypes.number,
  stackOnMobile: PropTypes.bool,
  showBookings: PropTypes.bool,
  showShortReviewsCopyOnDesktop: PropTypes.bool,
};

VendorRating.defaultProps = {
  lazyLoadStarRating: false,
  numberOfBookings: null,
  numberOfReviews: null,
  rating: null,
  stackOnMobile: false,
  showBookings: true,
  showShortReviewsCopyOnDesktop: false,
};

export default VendorRating;
