import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@tkww/the-bash-frontend';
import src from 'public/images-next/credit_card.svg';

const CreditCardIcon = (props) => <Icon {...props} src={src} />;

CreditCardIcon.propTypes = {
  alt: PropTypes.string,
};

CreditCardIcon.defaultProps = {
  alt: 'Accepts Online Payment',
};

export default CreditCardIcon;
