import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { getIconStateStyles } from '@tkww/the-bash-frontend';

import verifiedIcon from 'public/images-next/verified.svg';
import getSxAttributes from 'helpers/getSxAttributes';

const VerifiedIcon = (props) => {
  const { className, sx } = props;
  return (
    <Box className={className} padding={0}>
      <Box
        {...props}
        sx={(theme) => ({
          minWidth: 20,
          minHeight: 20,
          ...getIconStateStyles(verifiedIcon, null, null, false),
          ...getSxAttributes(theme, sx),
        })}
      />
    </Box>
  );
};

VerifiedIcon.propTypes = {
  className: PropTypes.string,
};

VerifiedIcon.defaultProps = {
  className: '',
};

export default VerifiedIcon;
