import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@tkww/the-bash-frontend';

import VerifiedIcon from 'components/Icons/Verified';
import { Chip } from '@mui/material';
import getSxAttributes from 'helpers/getSxAttributes';

export const VerifiedBookings = ({ numberOfBookings, sx }) => {
  let description = 'verified booking';
  if (numberOfBookings > 1) {
    description += 's';
  }

  return (
    <Chip
      sx={(theme) => ({
        width: 'fit-content',
        height: 27,
        backgroundColor: theme.palette.green[100],
        border: 'none',
        '& .MuiChip-avatar': {
          marginLeft: '2px',
        },
        ...getSxAttributes(theme, sx),
      })}
      avatar={<VerifiedIcon />}
      label={
        <>
          <Typography variant="body1" fontSize={12} textTransform="uppercase">
            <b>{numberOfBookings}</b> {description}
          </Typography>
        </>
      }
      variant="outlined"
    />
  );
};

VerifiedBookings.propTypes = {
  numberOfBookings: PropTypes.number.isRequired,
};

export default VerifiedBookings;
