import isEmpty from 'lodash.isempty';

import { gmConfig } from 'helpers/config';
import { sanitize } from 'helpers/buildCanonicalTag';

export const getWebPage = (router) => {
  const link = `https://${gmConfig.domains.www}${sanitize(router.asPath)}`;
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    id: link,
  };
};

export const buildReviewSchema = (review, profile) => ({
  '@context': 'https://schema.org/',
  '@type': 'Review',
  itemReviewed: {
    '@type': 'LocalBusiness',
    image: profile.thumbnailUrl,
    name: profile.profileName,
    address: {
      '@type': 'PostalAddress',
      addressLocality: review.gigRequestState,
      addressRegion: review.gigRequestCity,
    },
  },
  reviewRating: {
    '@type': 'Rating',
    ratingValue: review.satisfactionScore,
    worstRating: 0,
    bestRating: (5.0).toFixed(1),
  },
  author: {
    '@type': 'Person',
    name: `${review.clientFirstname} ${review.clientLastNameAbbreviation}.`,
  },
  reviewBody: review.text,
  datePublished: review.dateAdded,
});
export const buildVendorSchema = (vendor) => {
  const profileUrl = `https://${gmConfig.domains.www}${vendor.profileUrl}`;
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'LocalBusiness',
    address: {
      '@type': 'PostalAddress',
      addressLocality: vendor.cityName,
      addressRegion: vendor.stateOrProvinceAbbreviation,
    },
    name: vendor.profileName,
    description: vendor.primaryServiceName,
    url: profileUrl,
  };

  if (!isEmpty(vendor.thumbnailPhotoUrl)) {
    schema.image = [vendor.thumbnailPhotoUrl];
  } else {
    schema.image = [`https://${gmConfig.domains.www}/images-next/search/fallback-thumbnail.svg`];
  }

  if (vendor.numberOfReviews > 0) {
    schema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingCount: vendor.numberOfReviews,
      ratingValue: vendor.rating,
      bestRating: 5.0,
      worstRating: 0,
    };
  }

  if (vendor.showPriceRange) {
    schema.priceRange = `starting at $${vendor.payRangeLow}`;
  }

  return schema;
};

export const buildEventSchema = (calendarEvent) => {
  // Location is required for an Event
  // https://developers.google.com/search/docs/data-types/event#technical-guidelines
  if (isEmpty(calendarEvent.city) || isEmpty(calendarEvent.state)) return null;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: !isEmpty(calendarEvent.eventType) ? calendarEvent.eventType : calendarEvent.venue,
    startDate: calendarEvent.startDate,
    endDate: calendarEvent.endDate,
    location: {
      '@type': 'Place',
      name: calendarEvent.venue,
      address: {
        '@type': 'PostalAddress',
        addressLocality: calendarEvent.city,
        addressRegion: calendarEvent.state,
      },
    },
  };

  return schema;
};

export const buildVideoSchema = (video) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: video.title,
    description: video.description || video.title,
    thumbnailUrl: video.thumbnailUrl,
    uploadDate: video.createdAt,
    embedUrl: video.url,
  };

  return schema;
};
