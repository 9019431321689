import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@tkww/the-bash-frontend';
import src from 'public/images-next/attachment.svg';

const AttachmentIcon = (props) => <Icon {...props} src={src} />;

AttachmentIcon.propTypes = {
  alt: PropTypes.string,
};

AttachmentIcon.defaultProps = {
  alt: 'Sample floor plan URL',
};

export default AttachmentIcon;
