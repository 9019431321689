const getStartingPrice = (payRangeLow, showPriceRange, timePeriod) => {
  if (payRangeLow && showPriceRange) {
    switch (timePeriod) {
      case 'per event':
        return `${payRangeLow.toLocaleString()} per event`;
      case 'per hour':
        return `${payRangeLow.toLocaleString()} per hour`;
      default:
        return null;
    }
  }
  return null;
};

export default getStartingPrice;
